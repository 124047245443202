import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  Grid,
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  MenuItem,
  Select,
  FormControl,
  OutlinedInput,
} from "@mui/material";
import GetTasks from "./get_tasks";
import RefreshIcon from "@mui/icons-material/Refresh";

// Import the dictionary of URLs
import urlDictionary from "./operator_module_urls";

export default function Uitgevoerd({ machineNumber }) {
  const [searchTerm, setSearchTerm] = useState("");
  const [doorFilter, setDoorFilter] = useState("");
  const [actions, setActions] = useState([]);
  const [uniqueDoors, setUniqueDoors] = useState([]);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  const taskInstanceRef = useRef(new GetTasks());

  useEffect(() => {
    fetchTasks(true);
  }, [machineNumber]);

  const fetchTasks = async (initialLoad = false) => {
    try {
      if (initialLoad) {
        setLoading(true);
      } else {
        setUpdating(true);
      }

      const taskData = await taskInstanceRef.current.get_tasks(machineNumber, 7867);
      const doors = Array.from(new Set(taskData.map((action) => action.door)));

      setActions(taskData);
      setUniqueDoors(doors);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    } finally {
      setLoading(false);
      setUpdating(false);
    }
  };

  const filteredActions = actions.filter((action) => {
    const matchesSearch = action.actie.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesDoor = !doorFilter || action.door === doorFilter;
    return matchesSearch && matchesDoor;
  });

  return (
    <Box
      sx={{
        padding: 2,
        borderRadius: "8px",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: 2,
        }}
      >
        <Typography
          variant="h5"
          sx={{ fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sansSerif" }}
          gutterBottom
        >
          Uitgevoerd
        </Typography>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <TextField
            variant="outlined"
            size="small"
            placeholder="Zoek acties..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            sx={{ marginRight: 2, width: "70%" }}
          />
          <FormControl fullWidth size="small" sx={{ width: "70%" }}>
            <Select
              value={doorFilter}
              onChange={(e) => setDoorFilter(e.target.value)}
              displayEmpty
              input={<OutlinedInput />}
              sx={{ height: "40px" }}
            >
              <MenuItem value="">All</MenuItem>
              {uniqueDoors.map((door) => (
                <MenuItem key={door} value={door}>
                  {door}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </Box>

      <Divider />

      <Grid
        container
        spacing={2}
        sx={{ overflowY: "auto", flexGrow: 1, paddingRight: 1, maxHeight: "60vh" }}
      >
        {loading ? (
          <Typography sx={{ padding: 2 }}>Acties ophalen...</Typography>
        ) : filteredActions.length === 0 ? (
          <Typography sx={{ padding: 2 }}>Geen uitgevoerde acties gevonden</Typography>
        ) : (
          filteredActions.map((action, index) => (
            <Grid item xs={12} key={index}>
              <Box
                sx={{
                  border: "1px solid #ddd",
                  borderRadius: "8px",
                  padding: 2,
                  backgroundColor: "#f9f9f9",
                }}
              >
                <Typography variant="body2">
                  <b>Door:</b> {action.door}
                </Typography>
                <Typography variant="body2">
                  <b>Op:</b> {action.datum}
                </Typography>
                <Typography variant="body2">
                  <b>Taak:</b> <br /> {action.actie}
                </Typography>
              </Box>
            </Grid>
          ))
        )}
      </Grid>

      {updating && (
        <Typography
          variant="caption"
          sx={{ padding: 2, textAlign: "center", color: "gray", marginBottom: 2 }}
        >
          Bezig met het ophalen van nieuwe acties...
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2, // Adds spacing between the two buttons
          marginTop: 2,
        }}
      >
        <Button
          variant="contained"
          sx={{
            backgroundColor: "#32aca2",
            color: "#fff",
          }}
          onClick={() => fetchTasks(false)} // Trigger an update manually when button is clicked
        >
          <RefreshIcon />
        </Button>

        <Button
          variant="contained"
          sx={{
            backgroundColor: "#32aca2",
            color: "#fff",
          }}
          onClick={() => {
            // Use the machineNumber to look up the corresponding URL in the dictionary
            const url = urlDictionary[machineNumber];
            if (url) {
              window.open(url, "_blank"); // Redirect to the URL
            } else {
              console.log("URL not found for this machine number.");
            }
          }}
        >
          +
        </Button>
      </Box>
    </Box>
  );
}

Uitgevoerd.propTypes = {
  machineNumber: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};
