import SessionManager from "../../Authentication/SessionManager/session_manager";
import React, { useEffect, useState, useRef } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import HomeIcon from "@material-ui/icons/Home";
import { IconButton as MuiIconButton } from "@material-ui/core";
import GetTasks from "../../Dashboards/Scripts/get_tasks";
import datafanImage from "../../../assets/images/datafan/2023-12-Logo-Datafan.png";

const AdminPanel = () => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [filters, setFilters] = useState({
    datetime: "",
    monteur: "",
    machine: "",
    actie: "",
  });
  const [monteurOptions, setMonteurOptions] = useState([]);

  const taskInstanceRef = useRef(new GetTasks());

  useEffect(() => {
    SessionManager.checkActiveSession();
    SessionManager.setupActivityTracking();

    fetchTasks();

    return () => {
      SessionManager.removeActivityTracking();
    };
  }, []);

  const fetchTasks = async () => {
    try {
      console.log("Fetching tasks...");
      const taskData = await taskInstanceRef.current.get_all_tasks(7867);
      console.log("Fetched Tasks:", taskData); // Log the fetched tasks to verify the data structure
      setTasks(taskData);
      setFilteredTasks(taskData); // Initialize filtered tasks with all fetched tasks

      // Extract unique "Monteur" values and set them in state
      const uniqueMonteurValues = [
        ...new Set(taskData.map((task) => task.door || "Geen Operator")),
      ];
      setMonteurOptions(uniqueMonteurValues);
    } catch (error) {
      console.error("Error fetching tasks:", error);
    }
  };

  const handleFilterChange = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => {
      const updatedFilters = { ...prevFilters, [name]: value };
      applyFilters(updatedFilters); // Apply the filters immediately
      return updatedFilters;
    });
  };

  const applyFilters = (filterValues) => {
    const filtered = tasks.filter((task) => {
      return (
        (filterValues.datetime
          ? new Date(task.datetime)
              .toLocaleString()
              .toLowerCase()
              .includes(filterValues.datetime.toLowerCase())
          : true) &&
        (filterValues.monteur
          ? (task.door || "Geen Operator")
              .toLowerCase()
              .includes(filterValues.monteur.toLowerCase())
          : true) &&
        (filterValues.machine
          ? (task.machine || "Geen Machine")
              .toLowerCase()
              .includes(filterValues.machine.toLowerCase())
          : true) &&
        (filterValues.actie
          ? (task.actie || "Geen actie").toLowerCase().includes(filterValues.actie.toLowerCase())
          : true)
      );
    });
    setFilteredTasks(filtered);
  };

  return (
    <div>
      <MuiIconButton
        style={{ position: "absolute", top: "1%", left: "8%" }}
        onClick={() => (window.location.href = `/cockpit`)}
      >
        <HomeIcon />
      </MuiIconButton>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "flex-start",
          padding: 2,
        }}
      >
        {/* Filters */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            width: "80%",
            marginBottom: 2,
            paddingTop: "1%",
          }}
        >
          <TextField
            label="Filter Datum Tijd"
            variant="outlined"
            size="small"
            name="datetime"
            value={filters.datetime}
            onChange={handleFilterChange}
            sx={{ width: "23%" }}
          />
          <Select
            label="Filter Monteur"
            name="monteur"
            value={filters.monteur}
            onChange={handleFilterChange}
            displayEmpty
            sx={{
              width: "23%",
              border: "1px solid #ccc", // Basic border styling
              padding: "6px 8px", // Padding for better size
            }}
          >
            <MenuItem value="">
              <em>Alle Monteurs</em>
            </MenuItem>
            {monteurOptions.map((monteur, index) => (
              <MenuItem key={index} value={monteur}>
                {monteur}
              </MenuItem>
            ))}
          </Select>
          <TextField
            label="Filter Machine Nummer"
            variant="outlined"
            size="small"
            name="machine"
            value={filters.machine}
            onChange={handleFilterChange}
            sx={{ width: "23%" }}
          />
          <TextField
            label="Filter Werkzaamheden"
            variant="outlined"
            size="small"
            name="actie"
            value={filters.actie}
            onChange={handleFilterChange}
            sx={{ width: "23%" }}
          />
        </Box>

        {/* Table */}
        <TableContainer component={Paper} sx={{ width: "80%", maxHeight: "70vh" }}>
          <Table stickyHeader sx={{ width: "100%" }}>
            <TableHead sx={{ display: "contents!important" }}>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "#32aca2",
                    color: "#fff",
                    fontWeight: "bold",
                    width: "25%",
                  }}
                >
                  Datum Tijd
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#32aca2",
                    color: "#fff",
                    fontWeight: "bold",
                    width: "25%",
                  }}
                >
                  Monteur
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#32aca2",
                    color: "#fff",
                    fontWeight: "bold",
                    width: "25%",
                  }}
                >
                  Machine nummer
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "#32aca2",
                    color: "#fff",
                    fontWeight: "bold",
                    width: "25%",
                  }}
                >
                  Werkzaamheden
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {filteredTasks.length > 0 ? (
                filteredTasks.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell sx={{ padding: "10px" }}>
                      {row.datetime ? new Date(row.datetime).toLocaleString() : "N/A"}
                    </TableCell>
                    <TableCell sx={{ padding: "10px" }}>{row.door || "Geen Operator"}</TableCell>
                    <TableCell sx={{ padding: "10px" }}>{row.machine || "Geen Machine"}</TableCell>
                    <TableCell sx={{ padding: "10px" }}>{row.actie || "Geen actie"}</TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={4} align="center">
                    Geen gegevens gevonden
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>

      {/* Logo */}
      <Box
        sx={{
          position: "absolute",
          bottom: "1%",
          right: "8%",
          zIndex: 10,
        }}
      >
        <img src={datafanImage} alt="Datafan Logo" style={{ width: "150px", height: "auto" }} />
      </Box>
    </div>
  );
};

export default AdminPanel;
