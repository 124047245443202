import Dashboards from "pages/Dashboards";
import Authentication from "pages/Authentication";
import Login from "pages/Authentication/Login";
import ResetPassword from "pages/Authentication/ResetPassword";
import TwoFactor from "pages/Authentication/twofactor";
import ForgotPassword from "pages/Authentication/ForgotPassword";
import MachineCard from "pages/Dashboards/MachineCard";
import Historian from "pages/Dashboards/Historian";
import Test from "pages/Test";
import InputPanel from "pages/OperatorModule/InputPanel";
import AdminPanel from "pages/OperatorModule/AdminPanel";
import SuccessMessage from "pages/OperatorModule/InputPanel/successMessage";
import Home from "pages/Website/Home";
import Services from "pages/Website/Diensten";
import Contact from "pages/Website/Contact";
import HowItWorks from "pages/Website/Werkwijze";
import Workshop from "pages/Website/Workshop";
import Blog from "pages/Website/Blog";
import BlogTemplateLarge from "pages/Website/Blog/BlogTemplate/large";
import AboutUs from "pages/Website/About us";

const routes = [
  {
    name: "pages",
    columns: 1,
    rowsPerColumn: 2,
    collapse: [
      {
        name: "landing pages",
        collapse: [
          {
            name: "dashboard",
            route: "/cockpit",
            component: <Dashboards />,
          },
          {
            name: "machine card",
            route: "/cockpit/card",
            component: <MachineCard />,
          },
          {
            name: "historian",
            route: "/cockpit/historian/:historian_identifier",
            component: <Historian />,
          },
        ],
      },
      {
        name: "Testing",
        collapse: [
          {
            name: "Test",
            route: "/test",
            component: <Test />,
          },
        ],
      },
      {
        name: "authentication",
        collapse: [
          {
            name: "Authentication",
            route: "/authentication",
            component: <Authentication />,
          },
          {
            name: "Login",
            route: "/authentication/sign-in",
            component: <Login />,
          },
          {
            name: "Two Factor Authentication",
            route: "/authentication/two-factor",
            component: <TwoFactor />,
          },
          {
            name: "Reset Wachtwoord",
            route: "/authentication/reset-password/:account_identifier",
            component: <ResetPassword />,
          },
          {
            name: "Aanvraag reset wachtwoord",
            route: "/authentication/reset-password-request",
            component: <ForgotPassword />,
          },
        ],
      },
      {
        name: "operator-module",
        collapse: [
          {
            name: "operator-module",
            route: "/operator-module",
            component: "",
          },
          {
            name: "cockpit-input",
            route: "cockpit-input/:machine_factory_identifier",
            component: <InputPanel />,
          },
          {
            name: "success",
            route: "success/:machine_factory_identifier",
            component: <SuccessMessage />,
          },
          {
            name: "maintenance",
            route: "/maintenance",
            component: <AdminPanel />,
          },
        ],
      },
      {
        name: "website",
        collapse: [
          {
            name: "Home",
            route: "/home",
            component: <Home />,
          },
          {
            name: "Services",
            route: "/diensten",
            component: <Services />,
          },
          {
            name: "How it works",
            route: "/werkwijze",
            component: <HowItWorks />,
          },
          {
            name: "Workshop",
            route: "/workshop",
            component: <Workshop />,
          },
          {
            name: "Blog",
            collapse: [
              {
                name: "Updates",
                route: "/updates",
                component: <Blog />,
              },
              {
                name: "Updates",
                route: "/updates/:blog",
                component: <BlogTemplateLarge />,
              },
            ],
          },
          {
            name: "About us",
            route: "/over-ons",
            component: <AboutUs />,
          },
          {
            name: "Contact",
            route: "/contact",
            component: <Contact />,
          },
        ],
      },
    ],
  },
];

export default routes;
