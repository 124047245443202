const urlDictionary = {
  1: "https://www.datafan.nl/cockpit-input/7867692239OM81516",
  2: "https://www.datafan.nl/cockpit-input/7867837428OM23657",
  3: "https://www.datafan.nl/cockpit-input/7867853366OM20384",
  4: "https://www.datafan.nl/cockpit-input/7867302926OM22319",
  5: "https://www.datafan.nl/cockpit-input/7867286160OM32515",
  6: "https://www.datafan.nl/cockpit-input/7867092832OM55321",
  7: "https://www.datafan.nl/cockpit-input/7867768055OM11970",
  8: "https://www.datafan.nl/cockpit-input/7867767077OM86960",
  9: "https://www.datafan.nl/cockpit-input/7867330644OM18088",
  10: "https://www.datafan.nl/cockpit-input/7867599536OM76753",
  11: "https://www.datafan.nl/cockpit-input/7867598274OM52613",
  12: "https://www.datafan.nl/cockpit-input/7867950287OM33735",
  13: "https://www.datafan.nl/cockpit-input/7867901919OM41257",
  14: "https://www.datafan.nl/cockpit-input/7867187384OM76049",
  15: "https://www.datafan.nl/cockpit-input/7867382871OM89356",
  16: "https://www.datafan.nl/cockpit-input/7867918709OM16914",
  17: "https://www.datafan.nl/cockpit-input/7867790862OM59904",
  18: "https://www.datafan.nl/cockpit-input/7867479054OM46483",
  19: "https://www.datafan.nl/cockpit-input/7867738228OM16528",
  20: "https://www.datafan.nl/cockpit-input/7867338376OM64254",
  21: "https://www.datafan.nl/cockpit-input/7867169671OM23803",
  22: "https://www.datafan.nl/cockpit-input/7867770361OM70611",
  23: "https://www.datafan.nl/cockpit-input/7867718829OM78610",
  24: "https://www.datafan.nl/cockpit-input/7867247994OM40315",
  25: "https://www.datafan.nl/cockpit-input/7867559608OM61940",
  26: "https://www.datafan.nl/cockpit-input/7867244798OM45360",
  27: "https://www.datafan.nl/cockpit-input/7867654397OM70187",
  28: "https://www.datafan.nl/cockpit-input/7867366295OM54180",
  29: "https://www.datafan.nl/cockpit-input/7867834090OM52912",
  30: "https://www.datafan.nl/cockpit-input/7867697049OM46223",
  31: "https://www.datafan.nl/cockpit-input/7867463410OM78358",
  32: "https://www.datafan.nl/cockpit-input/7867905292OM56073",
  33: "https://www.datafan.nl/cockpit-input/7867610488OM74380",
  34: "https://www.datafan.nl/cockpit-input/7867771979OM19830",
  35: "https://www.datafan.nl/cockpit-input/7867613297OM41261",
  36: "https://www.datafan.nl/cockpit-input/7867726164OM89626",
  37: "https://www.datafan.nl/cockpit-input/7867990953OM57875",
  38: "https://www.datafan.nl/cockpit-input/7867760775OM20674",
  39: "https://www.datafan.nl/cockpit-input/7867765736OM71162",
  40: "https://www.datafan.nl/cockpit-input/7867253037OM61558",
  41: "https://www.datafan.nl/cockpit-input/7867359830OM10134",
  42: "https://www.datafan.nl/cockpit-input/7867593704OM36010",
  43: "https://www.datafan.nl/cockpit-input/7867735263OM52067",
  44: "https://www.datafan.nl/cockpit-input/7867269461OM54040",
  45: "https://www.datafan.nl/cockpit-input/7867115998OM65167",
  46: "https://www.datafan.nl/cockpit-input/7867663829OM71840",
  47: "https://www.datafan.nl/cockpit-input/7867287637OM44791",
  48: "https://www.datafan.nl/cockpit-input/7867534545OM89781",
  49: "https://www.datafan.nl/cockpit-input/7867826045OM73106",
  50: "https://www.datafan.nl/cockpit-input/7867491337OM89015",
  51: "https://www.datafan.nl/cockpit-input/7867814923OM23008",
  52: "https://www.datafan.nl/cockpit-input/7867203198OM87048",
  53: "https://www.datafan.nl/cockpit-input/7867583121OM67145",
  54: "https://www.datafan.nl/cockpit-input/7867257445OM11727",
  55: "https://www.datafan.nl/cockpit-input/7867323082OM42444",
  56: "https://www.datafan.nl/cockpit-input/7867626696OM91949",
  57: "https://www.datafan.nl/cockpit-input/7867176802OM98050",
  58: "https://www.datafan.nl/cockpit-input/7867692980OM50266",
  59: "https://www.datafan.nl/cockpit-input/7867998207OM65252",
  60: "https://www.datafan.nl/cockpit-input/7867772607OM40670",
  61: "https://www.datafan.nl/cockpit-input/7867269959OM48490",
  62: "https://www.datafan.nl/cockpit-input/7867131185OM91213",
  63: "https://www.datafan.nl/cockpit-input/7867929080OM74572",
  64: "https://www.datafan.nl/cockpit-input/7867627481OM39862",
  65: "https://www.datafan.nl/cockpit-input/7867874267OM64819",
  66: "https://www.datafan.nl/cockpit-input/7867521869OM47299",
  67: "https://www.datafan.nl/cockpit-input/7867315484OM76425",
  68: "https://www.datafan.nl/cockpit-input/7867482587OM16900",
  69: "https://www.datafan.nl/cockpit-input/7867298292OM87972",
  70: "https://www.datafan.nl/cockpit-input/7867523132OM69752",
  71: "https://www.datafan.nl/cockpit-input/7867860410OM84502",
  72: "https://www.datafan.nl/cockpit-input/7867668217OM43140",
  73: "https://www.datafan.nl/cockpit-input/7867718541OM45367",
  74: "https://www.datafan.nl/cockpit-input/7867675307OM88502",
  75: "https://www.datafan.nl/cockpit-input/7867646005OM41878",
  76: "https://www.datafan.nl/cockpit-input/7867318176OM96104",
  77: "https://www.datafan.nl/cockpit-input/7867171087OM44635",
  78: "https://www.datafan.nl/cockpit-input/7867946047OM91065",
  79: "https://www.datafan.nl/cockpit-input/7867909335OM79278",
  80: "https://www.datafan.nl/cockpit-input/7867416335OM15402",
  81: "https://www.datafan.nl/cockpit-input/7867415023OM57105",
  82: "https://www.datafan.nl/cockpit-input/7867144522OM75555",
  83: "https://www.datafan.nl/cockpit-input/7867127601OM94700",
  84: "https://www.datafan.nl/cockpit-input/7867326830OM65317",
  85: "https://www.datafan.nl/cockpit-input/7867612640OM39930",
  86: "https://www.datafan.nl/cockpit-input/7867656327OM22478",
  87: "https://www.datafan.nl/cockpit-input/7867168268OM58501",
  88: "https://www.datafan.nl/cockpit-input/7867254866OM54748",
  89: "https://www.datafan.nl/cockpit-input/7867564847OM46369",
  90: "https://www.datafan.nl/cockpit-input/7867969644OM21978",
  91: "https://www.datafan.nl/cockpit-input/7867848097OM60479",
  92: "https://www.datafan.nl/cockpit-input/7867678736OM91155",
  93: "https://www.datafan.nl/cockpit-input/7867822668OM72546",
  94: "https://www.datafan.nl/cockpit-input/7867894491OM39233",
  95: "https://www.datafan.nl/cockpit-input/7867746131OM10214",
  96: "https://www.datafan.nl/cockpit-input/7867454191OM77556",
  97: "https://www.datafan.nl/cockpit-input/7867880664OM69916",
  98: "https://www.datafan.nl/cockpit-input/7867688293OM19567",
  99: "https://www.datafan.nl/cockpit-input/7867639044OM92397",
  100: "https://www.datafan.nl/cockpit-input/7867677502OM65757",
  101: "https://www.datafan.nl/cockpit-input/7867691292OM64945",
  102: "https://www.datafan.nl/cockpit-input/7867897752OM39463",
  103: "https://www.datafan.nl/cockpit-input/7867757481OM54674",
  104: "https://www.datafan.nl/cockpit-input/7867845378OM61267",
  105: "https://www.datafan.nl/cockpit-input/7867536813OM15911",
  106: "https://www.datafan.nl/cockpit-input/7867480390OM33502",
  107: "https://www.datafan.nl/cockpit-input/7867862325OM75986",
  108: "https://www.datafan.nl/cockpit-input/7867731545OM61213",
  109: "https://www.datafan.nl/cockpit-input/7867259820OM12786",
  110: "https://www.datafan.nl/cockpit-input/7867143607OM20586",
  111: "https://www.datafan.nl/cockpit-input/7867415563OM90365",
  112: "https://www.datafan.nl/cockpit-input/7867418934OM60841",
  113: "https://www.datafan.nl/cockpit-input/7867185176OM97422",
  114: "https://www.datafan.nl/cockpit-input/7867281044OM35584",
  115: "https://www.datafan.nl/cockpit-input/7867393573OM85406",
  116: "https://www.datafan.nl/cockpit-input/7867389323OM84531",
  117: "https://www.datafan.nl/cockpit-input/7867465314OM22774",
  118: "https://www.datafan.nl/cockpit-input/7867480571OM18245",
  119: "https://www.datafan.nl/cockpit-input/7867486021OM31147",
  120: "https://www.datafan.nl/cockpit-input/7867466231OM98410",
};

export default urlDictionary;
