import SessionManager from "../../Authentication/SessionManager/session_manager";
import { API_KEY, API_KEY_2 } from "./config_tasks";

class GetTasks {
  async get_tasks(machine_number, project_code) {
    console.log(machine_number);
    // Retrieve the company and database keys from local storage or session manager
    const company_key_encrypted = localStorage.getItem("company_key");
    const company_key = SessionManager.getCompanyApiKey(company_key_encrypted);
    const database_key = SessionManager.decryptDatabase();

    // Construct the API URL similar to the existing call structure
    const api_url =
      `https://datafan-website-dashboards.azurewebsites.net/api/datafan_website_operator_messages_detailed?` +
      `code=${encodeURIComponent(API_KEY)}&` +
      `api_key=${encodeURIComponent(company_key)}&` +
      `database_id=${encodeURIComponent(database_key)}&` +
      `machine_number=${encodeURIComponent(machine_number)}&` +
      `customer_project=${encodeURIComponent(project_code)}`;

    try {
      // Fetch data from the API
      const response = await fetch(api_url);
      if (!response.ok) {
        console.error(`Error fetching tasks: ${response.status}`);
        return [];
      }

      // Parse the response into JSON
      const data = await response.json();

      // Transform and sort the API response into the required format
      const formattedTasks = data
        .map((task) => ({
          door: task.user, // Assuming the 'user' field is the person who performed the task
          datum: new Date(task.datetime).toLocaleString(), // Convert datetime to a readable format
          actie: task.category + ", " + task.task,
          datetime: new Date(task.datetime), // Include datetime for sorting
        }))
        .sort((a, b) => b.datetime - a.datetime); // Sort by datetime in descending order

      return formattedTasks; // Return without the datetime field
    } catch (error) {
      console.error("Failed to retrieve tasks:", error);
      return [];
    }
  }

  async get_all_tasks(project_code) {
    // Retrieve the company and database keys from local storage or session manager
    const company_key_encrypted = localStorage.getItem("company_key");
    const company_key = SessionManager.getCompanyApiKey(company_key_encrypted);
    const database_key = SessionManager.decryptDatabase();

    // Construct the API URL similar to the existing call structure
    const api_url =
      `https://datafan-website-dashboards.azurewebsites.net/api/datafan_website_operator_messages_detailed_all?` +
      `code=${encodeURIComponent(API_KEY_2)}&` +
      `api_key=${encodeURIComponent(company_key)}&` +
      `database_id=${encodeURIComponent(database_key)}&` +
      `customer_project=${encodeURIComponent(project_code)}`;

    try {
      // Fetch data from the API
      const response = await fetch(api_url);
      if (!response.ok) {
        console.error(`Error fetching tasks: ${response.status}`);
        return [];
      }

      // Parse the response into JSON
      const data = await response.json();

      // Transform and sort the API response into the required format
      const formattedTasks = data
        .map((task) => ({
          door: task.user, // Assuming the 'user' field is the person who performed the task
          datum: new Date(task.datetime).toLocaleString(), // Convert datetime to a readable format
          actie: task.category + ", " + task.task,
          machine: task.machine,
          datetime: new Date(task.datetime), // Include datetime for sorting
        }))
        .sort((a, b) => b.datetime - a.datetime); // Sort by datetime in descending order

      return formattedTasks; // Return without the datetime field
    } catch (error) {
      console.error("Failed to retrieve tasks:", error);
      return [];
    }
  }
}

export default GetTasks;
